.consult_full_width {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.main_consultation_div {
  width: 70vw;
  background-color: #122145;
  border-top-right-radius: 2vw;
  border-bottom-right-radius: 2vw;
}

.consultation_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.consult_textual {
  padding: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
}

.consultation_text {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 58px */
  letter-spacing: 1.16px;
}

.below_consultation_text {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.68px;
}

.consultBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  color: white;
  font-size: 1.2vw;
  width: 15vw;
  height: 3vw;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #01ead0;
  background: linear-gradient(180deg, #00fedf 0%, #065863 100%);
  box-shadow: 0px 0.2vw #01ead0;
}

.consult_call_txt {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.68px;
}

.meet_img_class {
  position: absolute;
  right: 0;
}

.meet_img {
  width: 35vw;
}
