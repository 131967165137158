@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700&display=swap");

.main_navbar_div {
  width: 100%;
  height: 8vw;
  background-color: #122145;
}
.myLogoClass {
  width: 6vw;
}
.my_navbar {
  width: 85% !important;
  margin-left: 6% !important;
}

.navbar {
  background-color: transparent !important;
}

.nav-link {
  color: white !important;
  /* margin: 2vw !important; */
  font-family: "Ubuntu";
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
width: 150px;
  
  
}

.nav-link:hover {
  /* margin: 2vw !important; */
  width: 150px;

  color: white !important;

  font-family: "Ubuntu";
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}


.navbar-toggler {
  padding: 0 px !important;
  font-size: 17px !important;
  line-height: 1;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0 px !important;
    font-size: 12px !important;
  }
  .nav-link {
    color: white !important;
    padding: 2vw !important;
    font-family: "Ubuntu";
    font-size: 3vw;
  }
}

@media only screen and (max-width: 991px) {
  .main_navbar_div {
    width: 100%;
    height: 110px;
    background-color: #122145;
  }
  .nav-link {
    color: white !important;
    padding: 2vw !important;
    font-family: "Ubuntu";
    font-size: 2.3vw;
  }

  .myLogoClass {
    width: 60px !important;
    height: 50px !important;
  }
}

@media only screen and (max-width: 400px) {
  .nav-link {
    color: white !important;
    padding: 2vw !important;
    font-family: "Ubuntu";
    font-size: 3vw;
  }
}

.navbtn {
  box-shadow: 0px 0.25vw #01EAD0 !important;

  width: 16vw;
  height: 4vw;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0.5px solid #057377;
  background: linear-gradient(180deg, #00fedf 0%, #065863 100%);
}

.col-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-9 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  padding-right: 2vw;
}

.strong_PHONE {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}

.phone_img {
  width: 1.7vw;
}

@media only screen and (max-width: 1300px) {
  .nav-link {
    color: white !important;
    /* margin: 2vw !important; */
    font-family: "Ubuntu";
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  width: 110px;
    
    
  }
  
  .nav-link:hover {
    /* margin: 2vw !important; */
    width: 110px;
  
    color: white !important;
  
    font-family: "Ubuntu";
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }

  .navbtn {
    width: 220px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 0.7vw !important;
  }



  .col-9 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    text-align: center;
    font-family: Ubuntu;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 2vw;
  }

  .strong_PHONE {
    color: #fff;
    text-align: center;
    font-family: Ubuntu;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .phone_img {
    width: 1.7vw;
  }

  .main_navbar_div {
    width: 100%;
    height: 10vw;
    background-color: #122145;
  }

}



@media only screen and (max-width: 991px) {
    .navbtn {
      width: 160px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 0.7vw !important;
    }
  
  
  
    .col-9 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-align: center;
      font-family: Ubuntu;
      font-size: 8px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.36px;
      padding-right: 2vw;
    }
  
    .strong_PHONE {
      color: #fff;
      text-align: center;
      font-family: Ubuntu;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;
    }
  
    .phone_img {
      width: 1.7vw;
    }
  
    .main_navbar_div {
      width: 100%;
      height: 10vw;
      background-color: #122145;
    }
    .phone_img {
        width: 17px;
      }
  }
  

  @media only screen and (max-width: 500px) {
    .navbtn {
      width: 130px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 1vw !important;
      
    }
  
  
  
    .col-9 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-align: center;
      font-family: Ubuntu;
      font-size: 8px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.36px;
      padding-right: 2vw;
    }
  
    .strong_PHONE {
      color: #fff;
      text-align: center;
      font-family: Ubuntu;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;
    }
  
    .phone_img {
      width: 15px !important;
    }
  
  
   
  }

  
@media only screen and (min-width: 991px){
  .nav1{
    margin-right: 1.5vw !important;
  }
 
}

@media only screen and (max-width: 991px){
  .main_navbar_div {
    width: 100%;
    height: 100px !important;
    background-color: #122145;
  }

  .navbtn{
    margin-left: 2vw;
  }

  .navbar-collapse{
    background-color: white !important;
    padding: 1vw;
    border-bottom-left-radius: 1.5vw;
    border-bottom-right-radius: 1.5vw;

  }

  .nav-link {
    color: black !important;
    /* margin: 2vw !important; */
    font-family: "Ubuntu";
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  width: 150px;
    
    
  }
  
  .nav-link:hover {
    /* margin: 2vw !important; */
    width: 150px;
  
    color: black !important;
  
    font-family: "Ubuntu";
    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  
  }
 
}