.main_questions_div{
    width: 100%;
    height: auto;
    margin-top: 5vw;
    margin-bottom: 3vw !important;
}

.questions_heading{
    color: #122145;
text-align: center;
font-family: Ubuntu;
font-size: 3vw;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.16px;
}

.my_accordiann{
    display: flex;
    width: 100% !important;
    justify-content: flex-start;
    flex-direction: column;
}

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700&display=swap");


.blue_accordian_background {
  width: 100%;
  height: 70vw !important;
  background-color: #062b63;
  margin-top: 6vw;
  padding-top: 3vw;
 position: relative;
}

.accordian_content_classs {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
}

.questions_text {
  color: #fff;
  text-align: center;
  font-family: "Ubuntu";
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.16px;
  margin-bottom: 0vw;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./../../Assets/downarrow.png) !important;
  transform: rotate(0deg) !important; 
  transition: transform 0.3s !important; 
  
}

.accordion-button:after {
  background-image: url(./../../Assets/uparrow.png) !important;
  background-size: 1vw !important;
  padding-right: 1vw !important;
  transform: rotate(0deg) !important; 
  transition: transform 0.3s !important; 
}

.accordion-button {
  color: #00F5D8 !important;
  font-family: Ubuntu;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  background-color: #fff !important;
  border-radius: 0vw !important;
  border: none !important;
  border-color: #fff !important;
  padding: 1.1vw !important;
  padding-left: 0px !important;
  box-shadow: none !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

}

.accordion-body {

  color: #535353;;
  font-family: Ubuntu;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  border: none !important;
  border-color: #fff !important;
  padding: 0vw 4vw 1vw 0vw !important;
  /* padding: 0px !important; */
  padding-right: 20vw !important ;

}

.accordion-item {
  margin: 1.2vw;
  border-radius: 0vw;
  border: none !important;
  border-color: #fff !important;
  border-bottom: 2px solid #122145 !important;
}

.accordion {
  border-radius: 0vw !important;
  border: none !important;
  border-color: #fff !important;

  width: 58%;
}

#accordionPanelsStayOpenExample {
  border-radius: 0vw !important;
  border: none !important;
  border-color: #fff !important;
}

.accordion-header {
  border-radius: 0vw;
  border: none !important;
  border-color: #fff !important;
}

/* .accordion-item: {
  border-radius: 0vw !important;
  border-bottom: 5px solid #122145 !important;
  border: none !important;
  border-color: #fff !important;
} */

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-item:last-of-type {
  border-radius: 0vw !important;
}
.women_img{
  position:absolute ;
    
    right: 130px;
    width: 342px;
height: 456px;
top: -140px;
}
@media only screen and (max-width: 2800px) {
  .women_img{
    position:absolute ;
      
      right: 130px;
      width: 342px;
  height: 456px;
  top: -140px;
  }
}
@media only screen and (max-width: 2250px) {
  .women_img{
    position:absolute ;
      
      right: 100px;
      width: 342px;
  height: 456px;
  top: -130px;
  }
}
@media only screen and (max-width: 2250px) {
  .women_img{
    position:absolute ;
      
      right: 100px;
      width: 342px;
  height: 456px;
  top: -130px;
  }
}


@media only screen and (max-width: 2050px) {
  .women_img{
    position:absolute ;
      
      right: 60px;
      width: 312px;
  height: 406px;
  top: -110px;
  }
}
@media only screen and (max-width: 1780px) {
  .women_img{
    position:absolute ;
      
      right: 40px;
      width: 312px;
  height: 406px;
  top: -100px;
  }
}


@media only screen and (max-width: 1520px) {
  .women_img{
    position:absolute ;
      
      right: 20px;
      width: 252px;
  height: 356px;
  top: -90px;
  }
}

@media only screen and (max-width: 1300px) {
  .women_img{
    position:absolute ;
      
      right: 10px;
      width: 232px;
  height: 306px;
  top: -70px;
  }
}
@media only screen and (max-width: 1050px) {
  .women_img{
    position:absolute ;
      
      right: -20px;
      width: 232px;
  height: 306px;
  top: -50px;
  }
}
@media only screen and (max-width: 768px) {

  .accordion {
    border-radius: 0vw !important;
    border: none !important;
    border-color: #fff !important;

    width: 78%;
    /* bs-accordion-btn-icon-transform: 0; */
  }
 
  .accordion-button:after {
    background-image: url(./../../Assets/uparrow.png) !important;
    background-size: 2vw !important;
    padding-right: 1vw !important;
    padding-left: 2vw !important;
    /* padding-top: -5vw !important; */
    margin-bottom: -1.5vw;
   
  }

  .accordion-button {
    padding-top: 2vw !important;
    /* padding: 1vw !important; */
    padding-left: 0px !important;
    
  }


  .accordion-button {
    color: #00F5D8 !important;
    font-family: Ubuntu;
    font-size: 2vw !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #fff !important;
    border-radius: 0vw !important;
    border: none !important;
    border-color: #fff !important;
    padding: 1.1vw !important;
    padding-left: 0px !important;
    box-shadow: none !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  

  
  }
  
  .accordion-body {
    color: #535353;;
    
    font-family: Ubuntu;
    font-size: 1.4vw !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    border: none !important;
    border-color: #fff !important;
    padding: 0vw 4vw 1vw 0vw !important;
    padding-right: 20vw !important ;

    
  }

}
